import { useQuery, useMutation } from "@apollo/client";
import {
  ArrayDataTable,
  Breadcrumbs,
  Button,
  DetailsTable,
  Flex,
  Layout,
  Pill,
  Surface,
  Text,
  toast,
} from "@heart/components";
import _ from "lodash";
import { Fragment } from "react";
import {
  adminRootPath,
  agencyServicesPath,
  newAgencyServiceRecordPath,
  preventionAgencyServiceReferralsPath,
} from "routes";
import { If } from "tsx-control-statements/components";

import Spinner from "@components/Spinner";
import { translationWithRoot } from "@components/T";
import AgencyServiceRecordsTable from "@components/prevention/agency_service_records/AgencyServiceRecordsTable";

import updateAgencyServiceReferral from "@graphql/mutations/prevention/UpdateAgencyServiceReferral.graphql";
import AgencyServiceReferralQuery from "@graphql/queries/prevention/AgencyServiceReferral.graphql";

import DocumentsAndRequirementsTab from "../tabs/documents_and_requirements_tab/DocumentsAndRequirementsTab";
import {
  IN_PROGRESS_STATUS,
  REJECTED_STATUS,
  PENDING_STATUS,
} from "./constants";

const { t, T } = translationWithRoot("prevention");

const { default: BintiPropTypes } = require("@lib/BintiPropTypes");

/**
 * AgencyServiceReferralDetails component displays the details of an agency service referral.
 *
 * @param {string} props.agencyServiceReferralId - The ID of the agency service referral to display.
 * @returns {JSX.Element} The rendered component.
 */
const AgencyServiceReferralDetails = ({ agencyServiceReferralId }) => {
  const {
    data: agencyServiceReferralData,
    loading: agencyServiceReferralLoading,
    refetch,
  } = useQuery(AgencyServiceReferralQuery, {
    variables: { agencyServiceReferralId },
  });

  const [updateReferral, { loading: updateLoading }] = useMutation(
    updateAgencyServiceReferral,
    {
      onCompleted: () => {
        toast.success({
          title: t("agency_service_referrals.success_toast.title"),
          message: t("agency_service_referrals.success_toast.message"),
        });
        refetch();
      },
      onError: () => {
        toast.negative({
          title: t("agency_service_referrals.error_toast.title"),
          message: t("agency_service_referrals.error_toast.message"),
        });
      },
    }
  );

  const acceptReferral = async () => {
    await updateReferral({
      variables: {
        id: agencyServiceReferralId,
        status: IN_PROGRESS_STATUS,
      },
    });
  };

  const rejectReferral = async () => {
    await updateReferral({
      variables: {
        id: agencyServiceReferralId,
        status: REJECTED_STATUS,
      },
    });
  };

  if (agencyServiceReferralLoading) {
    return <Spinner />;
  }

  const agencyServiceReferral =
    agencyServiceReferralData?.agencyServiceReferral || {};

  const serviceTypes =
    agencyServiceReferral?.agencyService?.serviceTypes.join(", ");
  const areasOfCoverage =
    agencyServiceReferral?.agencyService?.areasOfCoverage.join(", ");
  const serviceRates = agencyServiceReferral?.billableRates
    .map(rate => `${rate.code}-${rate.label}`)
    .join("; ");

  const ebpDisplayTitle =
    agencyServiceReferral.agencyService?.evidenceBasedPractice?.displayTitle;
  const serviceReferralTitle = `${t(
    "agency_service_referrals.dashboard.title"
  )}: ${ebpDisplayTitle} `;
  const capitalizedStatus = _.startCase(agencyServiceReferral.status);

  const referralInfo = (
    <Surface title={t("agency_service_referrals.details")}>
      <DetailsTable
        details={[
          {
            label: t("agency_services.referring_agency"),
            value: agencyServiceReferral.agency.name,
          },
          {
            label: t("agency_services.name"),
            value: agencyServiceReferral.agencyService.name,
          },
          {
            label: t("agency_services.ffpsa_program"),
            value: agencyServiceReferral.agencyService.isFfpsa ? "Yes" : "No",
          },
          {
            label: t("agency_services.corresponding_ebp"),
            value: ebpDisplayTitle,
          },
          {
            label: t("agency_services.service_types"),
            value: serviceTypes.length === 0 ? t("none") : serviceTypes,
          },
          {
            label: t("agency_services.areas_of_coverage"),
            value: areasOfCoverage.length === 0 ? t("none") : areasOfCoverage,
          },
          {
            label: t("agency_service_referrals.form_page.service_rates"),
            value: serviceRates,
          },
          {
            label: t("agency_service_referrals.number_of_service_units"),
            value:
              agencyServiceReferral.numberOfServiceUnits === null
                ? t("none")
                : agencyServiceReferral.numberOfServiceUnits,
          },
          {
            label: t("agency_service_referrals.form_page.notes"),
            value:
              agencyServiceReferral.notes === 0
                ? t("none")
                : agencyServiceReferral.notes,
          },
        ]}
      />
    </Surface>
  );

  const recipientInfo = (
    <Surface title={t("agency_services.recipients.title")}>
      <ArrayDataTable
        columns={[
          {
            cell: "agencyHuman.fullName",
            columnName: {
              name: t("agency_services.recipients.name"),
            },
            columnSort: () => {},
            id: "recipient_name",
          },
          {
            cell: "startDate",
            columnName: {
              name: t("agency_services.start_date"),
            },
            columnSort: () => {},
            id: "recipient_start_date",
          },
          {
            cell: "endDate",
            columnName: {
              name: t("agency_services.end_date"),
              justify: "end",
            },
            columnSort: () => {},
            id: "recipient_end_date",
          },
        ]}
        data={agencyServiceReferral?.agencyServiceReferralRecipients}
      />
    </Surface>
  );

  const serviceRecordInfo = (
    <Surface
      title={t("agency_service_referrals.associated_service_records")}
      secondary={
        <Button
          href={newAgencyServiceRecordPath({
            agency_service_referral_id: agencyServiceReferralId,
          })}
          variant="primary"
        >
          {<T t="agency_service_records.new" />}
        </Button>
      }
    >
      <AgencyServiceRecordsTable
        agencyServiceReferralId={agencyServiceReferralId}
      />
    </Surface>
  );

  const serviceReferralDocs = (
    <Surface title={t("documents_and_requirements.section_title")}>
      <DocumentsAndRequirementsTab
        hideTitle={true}
        requirementHolderId={Number(agencyServiceReferralId)}
        requirementHolderType={"AgencyServiceReferral"}
        recipients={agencyServiceReferral?.recipients}
      />
    </Surface>
  );

  return (
    <Layout
      pageTitle={
        <Flex row>
          <Flex grow>
            <Text>
              {serviceReferralTitle}
              <Pill text={capitalizedStatus} variant="alert" />
            </Text>
          </Flex>
          <If condition={agencyServiceReferral.status === PENDING_STATUS}>
            <Button
              onClick={acceptReferral}
              type="submit"
              variant="primary"
              disabled={updateLoading}
            >
              {t("agency_service_referrals.accept")}
            </Button>
            <Button
              onClick={rejectReferral}
              type="reset"
              variant="danger"
              disabled={updateLoading}
            >
              {t("agency_service_referrals.reject")}
            </Button>
          </If>
        </Flex>
      }
      breadcrumbs={
        <Breadcrumbs
          pages={[
            {
              label: <T t="agency_service_referrals.breadcrumbs.home"></T>,
              href: adminRootPath(),
            },
            {
              label: <T t="breadcrumbs.children_and_youth"></T>,
              href: agencyServicesPath(),
            },
            {
              label: <T t="profile_page.tabs.agency_service_referrals"></T>,
              href: preventionAgencyServiceReferralsPath(),
            },
            {
              label: ebpDisplayTitle,
              href: "#",
            },
          ]}
        />
      }
      main={{
        content: (
          <Fragment>
            {referralInfo}
            {recipientInfo}
            {serviceRecordInfo}
            {serviceReferralDocs}
          </Fragment>
        ),
      }}
    />
  );
};

AgencyServiceReferralDetails.propTypes = {
  agencyServiceReferralId: BintiPropTypes.ID,
};

export default AgencyServiceReferralDetails;
