import {
  InputHidden,
  InputEthnicities,
  inputEthnicitiesPropTypes,
} from "@heart/components";
import I18n from "i18n-js";
import PropTypes from "prop-types";
import { Fragment, useState } from "react";

import AfcarsRequired from "@components/ccwis_demo/AfcarsRequired";

const defaultEthnicitiesLabel = useRacesInput =>
  useRacesInput
    ? I18n.t("views.common.of_hispanic_origin_title")
    : I18n.t("views.common.ethnicity");

/** ### Usage
 *
 * A component for collecting how a person identifies their races and ethnicities
 * As we are transitioning from an old ethnicities object to a new schema in which
 * ethnicities and races are two categories, this also handles that migration by
 * basing whether to show the old vs new UI based on the useRacesInput argument.
 */
const EthnicityAndRaceWrapper = ({
  useRacesInput,
  selectedEthnicities,
  ethnicitiesInputName,
  ethnicitiesIsRequired = false,
  racesIsRequired = false,
  ethnicitiesDescription,
  racesDescription,
  ethnicitiesIsAfcarsRequired = false,
  selectedRaces = null,
  racesInputName = null,
}) => {
  const [ethnicities, setEthnicities] = useState(selectedEthnicities);
  const [races, setRaces] = useState(selectedRaces);

  const ethnicitiesLabel = (
    <Fragment>
      {defaultEthnicitiesLabel(useRacesInput)}
      <If condition={ethnicitiesIsRequired}>*</If>
      <If condition={ethnicitiesIsAfcarsRequired}>
        {" "}
        <AfcarsRequired />
      </If>
    </Fragment>
  );

  const racesLabel = (
    <Fragment>
      {I18n.t("views.common.race_select_title")}
      <If condition={racesIsRequired}>*</If>
    </Fragment>
  );

  return (
    <div>
      <InputEthnicities
        useRacesInput={useRacesInput}
        required={false}
        selectedEthnicities={ethnicities}
        onEthnicitiesChange={newEthnicities => {
          setEthnicities(newEthnicities);
        }}
        selectedRaces={races}
        onRacesChange={newRaces => {
          setRaces(newRaces);
        }}
        ethnicitiesLabel={ethnicitiesLabel}
        racesLabel={racesLabel}
        ethnicitiesDescription={ethnicitiesDescription}
        racesDescription={racesDescription}
      />
      <InputHidden
        name={ethnicitiesInputName}
        value={JSON.stringify(ethnicities)}
      />
      <InputHidden name={racesInputName} value={JSON.stringify(races)} />
    </div>
  );
};

EthnicityAndRaceWrapper.propTypes = {
  ethnicitiesInputName: PropTypes.string.isRequired,
  racesInputName: PropTypes.string,
  ethnicitiesIsRequired: PropTypes.bool,
  racesIsRequired: PropTypes.bool,
  isAfcarsRequired: PropTypes.bool,
  ...inputEthnicitiesPropTypes,
};

export default EthnicityAndRaceWrapper;
