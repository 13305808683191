import { translationWithRoot } from "@components/T";

import { formatAsLongDate } from "@lib/dates";

const { t } = translationWithRoot("common");

/**
 * Given a human's full name and date of birth, generates a label containing the
 * name and the date of birth in parentheses, if available.
 *
 * @param {string} fullName - The full name of the human.
 * @param {Date|string} [dateOfBirth] - The human's date of birth.
 * @param {Boolean} [isEstimatedDateOfBirth] - Boolean as to whether the human has an estimated date of birth
 * @param {Date|string} [estimatedDateOfBirth] - The human's estimated date of birth.
 * @param {Object} [options] - Options passed to `DateTime.fromISO`.
 * @param {Boolean} [useUnknown] - Boolean as to whether to display "Unknown" for date of birth when it is not available.
 * @returns {string} The human's full name, with date of birth appended in parentheses if availabe.
 */
const createNameAndDOBLabel = ({
  fullName,
  dateOfBirth,
  isEstimatedDateOfBirth,
  estimatedDateOfBirth,
  options,
  useUnknown = false,
}) => {
  if (isEstimatedDateOfBirth) {
    return t("name_with_estimated_dob", {
      name: fullName,
      dob: formatAsLongDate(estimatedDateOfBirth, options),
    });
  }
  if (dateOfBirth || useUnknown) {
    return t("name_with_dob", {
      name: fullName,
      dob: dateOfBirth ? formatAsLongDate(dateOfBirth, options) : t("unknown"),
    });
  }
  return fullName;
};

export default createNameAndDOBLabel;
