import { Flex, InputDate, InputCheckbox } from "@heart/components";
import { DateTime } from "luxon";
import PropTypes from "prop-types";
import { useState } from "react";

import { translationWithRoot } from "@components/T";

const { t } = translationWithRoot("agency_human.form.personal_info");

/** Date of Birth inputs for Intake Child
 *
 * The user can also specify whether the date of birth is estimated.
 */
const DateOfBirthInputs = ({
  dateOfBirth,
  estimatedDateOfBirth,
  isAfcarsRequired,
}) => {
  const [date, setDate] = useState(dateOfBirth || estimatedDateOfBirth);
  const [isEstimatedDateOfBirth, setIsEstimatedDateOfBirth] = useState(
    !!estimatedDateOfBirth
  );

  return (
    <Flex column>
      <InputDate
        label={t("date_of_birth")}
        afcarsRequired={isAfcarsRequired}
        maxDate={DateTime.local().toFormat("yyyy-MM-dd")}
        value={date}
        onChange={setDate}
        name="child[date_of_birth]"
      />
      <InputCheckbox
        label={t("is_estimated_dob")}
        value={isEstimatedDateOfBirth}
        onChange={setIsEstimatedDateOfBirth}
        name="child[is_estimated_dob]"
      />
    </Flex>
  );
};
DateOfBirthInputs.propTypes = {
  dateOfBirth: PropTypes.string,
  estimatedDateOfBirth: PropTypes.string,
  isAfcarsRequired: PropTypes.bool,
};

export default DateOfBirthInputs;
