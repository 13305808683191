import { Upload, Surface, Link } from "@heart/components";
import { isEmpty } from "lodash";
import PropTypes from "prop-types";
import React, { Fragment } from "react";

import { translationWithRoot } from "@components/T";

const { t } = translationWithRoot("form_file_uploader");

/**
 * FormFileUploader component for uploading files to a questionnaire response.
 *
 * @param {Object[]} files - Array of existing files
 * @param {string} title - Title of the questionnaire question
 * @param {string} description - Description of the questionnaire question
 * @param {boolean} disabled - Disabled state of the uploader
 */
const FormFileUploader = ({ files, title, description, disabled }) => (
  <Fragment>
    <Surface title={title} subtitle={description}>
      <Upload disabled={disabled} onUpload={(/* TODO ENG-20814 */) => {}} />
      {/* TODO ENG-20814 <ShrineErrorAlert /> */}
    </Surface>
    <Surface title={t("uploaded_files")}>
      {(files || []).map(file => (
        <Link href={file.file_url} key={file.id} target="_blank">
          {file.description}
        </Link>
      ))}
      <If condition={isEmpty(files)}>{t("no_files_to_display")}</If>
    </Surface>
  </Fragment>
);

FormFileUploader.propTypes = {
  files: PropTypes.arrayOf(PropTypes.object).isRequired,
  title: PropTypes.number.isRequired,
  description: PropTypes.string.isRequired,
  disabled: PropTypes.bool,
};

export default FormFileUploader;
