import PropTypes from "prop-types";

import useBase64SearchParam from "@lib/react-use/useBase64SearchParam";
import useFeatureFlag from "@lib/useFeatureFlag";

import Genogram from "../genogram/WIPGenogram";
import RelationshipsTable from "./RelationshipsTable";

/** Component to toggle between displaying relationship information as a
 * table or as a genogram
 */
const RelationshipsTableOrGenogram = ({
  familyFindingEnabled,
  childAgencyHumanId,
  addPersonFormPath,
  downloadRelationshipsCsvPath,
}) => {
  const view = useBase64SearchParam("view");
  const { flag: ffGenogram102024 } = useFeatureFlag("ff_genogram_10_2024");

  if (view === "genogram" && ffGenogram102024 && familyFindingEnabled) {
    return (
      <Genogram
        addPersonFormPath={addPersonFormPath}
        childAgencyHumanId={childAgencyHumanId}
      />
    );
  }

  return (
    <RelationshipsTable
      tab="relationships"
      familyFindingEnabled={familyFindingEnabled}
      addPersonFormPath={addPersonFormPath}
      keystoneAgencyHumanId={childAgencyHumanId}
      downloadRelationshipsCsvPath={downloadRelationshipsCsvPath}
    />
  );
};
RelationshipsTableOrGenogram.propTypes = {
  /** Genogram is only available for agencies with family finding enabled,
   * the relationships table is available to agencies with prevention as well
   */
  familyFindingEnabled: PropTypes.bool,
  addPersonFormPath: PropTypes.string.isRequired,
  childAgencyHumanId: PropTypes.number.isRequired,
  downloadRelationshipsCsvPath: PropTypes.string.isRequired,
};

export default RelationshipsTableOrGenogram;
