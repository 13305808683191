import {
  InputCheckbox,
  InputCheckboxGroup,
  InputFilterable,
  InputFilterableGraphQL,
  InputText,
  InputTextarea,
} from "@heart/components";
import {
  fromLegacyCheckboxGroupValue,
  toLegacyCheckboxGroupValue,
} from "@heart/components/inputs/InputCheckboxGroup";
import { curry, isEqual, isNil, pull, snakeCase } from "lodash";
import PropTypes from "prop-types";
import { Fragment } from "react";

import { translationWithRoot } from "@components/T";
import { relationshipErrors } from "@components/agency_humans/AgencyHumanFormComputations";

import KinshipRelationshipTypes from "@graphql/queries/KinshipRelationshipTypes.graphql";

import {
  mapConstantSetToValues,
  mapConstantToValue,
  mapNestedEnumConstantToValues,
} from "@lib/constantsConversion";
import generateId from "@lib/generateId";

import {
  RELATIVE,
  FICTIVE_KIN,
  UNKNOWN,
  PARTNER,
  OTHER_PARTNER_STATUS,
  OTHER,
  LEVELS_OF_SUPPORT,
  RELATIONSHIP_CATEGORIES,
  PARTNER_STATUSES,
  PLACEMENT_PROVIDER_ELIGIBILITIES,
  WORKER,
  NOT_VERIFIED,
} from "@root/constants";

import BaseRelationshipToChildSection from "./BaseRelationshipToChildSection";
import { resetRelationshipState } from "./transitionRelationshipState";

const { t } = translationWithRoot("agency_human.form.relationship_to_child");

const transformQueryData = ({ kinshipRelationshipTypes = {} }) =>
  pull(Object.keys(kinshipRelationshipTypes), "__typename").reduce(
    (values, key) => [
      ...values,
      {
        label: I18n.t(
          `activerecord.enums.relationships.degrees_of_relation.${snakeCase(
            key
          )}`
        ),
        options: mapConstantSetToValues({
          constant: kinshipRelationshipTypes[key],
          translationKey: "relationships.kinship_relationships",
        }),
      },
    ],
    []
  );

/** Set of form fields representing the relationship between a connection
 * and a child
 */
const RelationshipToChildSection = ({ relationship, setFormAttribute }) => {
  if (isNil(relationship)) return null;

  const {
    relationshipCategory,
    kinshipRelationship,
    kinshipRelationshipOtherDescription = "",
    partnerStatus,
    levelsOfSupport = [],
    levelsOfSupportOtherDescription = "",
    fictiveKinDescription = "",
    additionalRelationshipDetails = "",
    placementProviderEligibilitiesJson = {},
    placementProviderEligibilitiesDetails,
    verificationSource,
  } = relationship;

  const isFictiveKin = relationshipCategory?.value === FICTIVE_KIN;
  const isRelative = relationshipCategory?.value === RELATIVE;
  const relationshipIsUnknown = relationshipCategory?.value === UNKNOWN;

  const resetRelationship = category =>
    setFormAttribute(resetRelationshipState({ category, relationship }));

  const setRelationshipAttribute = curry((attribute, value) => {
    if (!isNil(value) && !isEqual(value, relationship[attribute])) {
      setFormAttribute({ ...relationship, [attribute]: value });
    }
  });

  return (
    <Fragment>
      <InputFilterable
        label={t("relationship_category")}
        required
        onChange={resetRelationship}
        defaultValue={mapConstantToValue({
          value: UNKNOWN,
          translationKey: "relationships.category",
        })}
        value={relationshipCategory}
        values={mapConstantSetToValues({
          constant: RELATIONSHIP_CATEGORIES,
          translationKey: "relationships.category",
        })}
      />
      <If
        condition={[RELATIVE, FICTIVE_KIN].includes(
          relationshipCategory?.value
        )}
      >
        <If condition={isFictiveKin}>
          <InputText
            label={t("relationship_type")}
            required
            onChange={setRelationshipAttribute("fictiveKinDescription")}
            value={fictiveKinDescription}
          />
        </If>
        <If condition={isRelative}>
          <InputFilterableGraphQL
            label={t("relationship_type")}
            required
            onChange={value =>
              setFormAttribute({
                ...relationship,
                kinshipRelationship: value,
                partnerStatus: undefined,
                kinshipRelationshipOtherDescription: undefined,
              })
            }
            value={kinshipRelationship}
            query={KinshipRelationshipTypes}
            transformQueryData={transformQueryData}
          />
          <If condition={kinshipRelationship?.value === PARTNER}>
            <InputFilterable
              label={t("partner_status")}
              required
              onChange={setRelationshipAttribute("partnerStatus")}
              value={partnerStatus}
              values={mapConstantSetToValues({
                constant: PARTNER_STATUSES,
                translationKey: "relationships.partner_status",
              })}
            />
          </If>
          <If
            condition={
              kinshipRelationship?.value === OTHER ||
              partnerStatus?.value === OTHER_PARTNER_STATUS
            }
          >
            <InputText
              label={t("please_specify")}
              required
              onChange={setRelationshipAttribute(
                "kinshipRelationshipOtherDescription"
              )}
              value={kinshipRelationshipOtherDescription}
            />
          </If>
        </If>
        <BaseRelationshipToChildSection
          relationship={relationship}
          setRelationshipAttribute={setRelationshipAttribute}
        />
        <InputCheckboxGroup
          /** We're using a dynamically generated key to solve a bug where the
           * checkboxes would appear unchecked after the "Are you sure?" modal
           * was cancelled. There may be other solutions to this, but they'll
           * likely require a more intentional pass over our Modal/Alert components
           * TODO: https://binti.atlassian.net/browse/ENG-15612
           */
          key={generateId()}
          label={t("placement_option")}
          error={relationshipErrors.getPlacementEligibilitiesError({
            placementProviderEligibilitiesJson,
          })}
          onChange={setRelationshipAttribute(
            "placementProviderEligibilitiesJson"
          )}
          value={placementProviderEligibilitiesJson}
          values={mapConstantSetToValues({
            constant: PLACEMENT_PROVIDER_ELIGIBILITIES,
            translationKey: "relationships.placement_provider_eligibilities",
          })}
        />
        <InputTextarea
          rows={2}
          label={t("placement_option_details")}
          onChange={setRelationshipAttribute(
            "placementProviderEligibilitiesDetails"
          )}
          value={placementProviderEligibilitiesDetails}
        />
        <InputCheckboxGroup
          /** We're using a dynamically generated key to solve a bug where the
           * checkboxes would appear unchecked after the "Are you sure?" modal
           * was cancelled. There may be other solutions to this, but they'll
           * likely require a more intentional pass over our Modal/Alert components
           * TODO: https://binti.atlassian.net/browse/ENG-15612
           */
          key={generateId()}
          label={t("level_of_support")}
          onChange={los => {
            const legacyVal = toLegacyCheckboxGroupValue(los);
            /** Clear the description for Other if it is unchecked */
            setFormAttribute({
              ...relationship,
              levelsOfSupport: legacyVal,
              levelsOfSupportOtherDescription: legacyVal.includes(OTHER)
                ? levelsOfSupportOtherDescription
                : "",
            });
          }}
          value={fromLegacyCheckboxGroupValue(levelsOfSupport)}
          values={mapNestedEnumConstantToValues({
            constant: LEVELS_OF_SUPPORT,
          })}
        />
        <If condition={levelsOfSupport.includes(OTHER)}>
          <InputText
            label={t("please_specify")}
            required
            value={levelsOfSupportOtherDescription}
            onChange={setRelationshipAttribute(
              "levelsOfSupportOtherDescription"
            )}
          />
        </If>
      </If>
      <If condition={!relationshipIsUnknown}>
        <InputCheckbox
          label={t("worker_confirmed_relationship")}
          onChange={checked =>
            setRelationshipAttribute("verificationSource")(
              checked ? WORKER : NOT_VERIFIED
            )
          }
          value={verificationSource === WORKER}
        />
      </If>
      <InputTextarea
        label={t("additional_relationship_details")}
        onChange={setRelationshipAttribute("additionalRelationshipDetails")}
        value={additionalRelationshipDetails}
      />
    </Fragment>
  );
};
RelationshipToChildSection.propTypes = {
  relationship: PropTypes.shape({
    relationshipCategory: PropTypes.shape({
      label: PropTypes.string,
      value: PropTypes.string,
    }),
    parentalLine: PropTypes.arrayOf(
      PropTypes.shape({
        label: PropTypes.string,
        value: PropTypes.string,
      })
    ),
    lineageType: PropTypes.shape({
      label: PropTypes.string,
      value: PropTypes.string,
    }),
    kinshipRelationship: PropTypes.shape({
      label: PropTypes.string,
      value: PropTypes.string,
    }),
    kinshipRelationshipOtherDescription: PropTypes.string,
    partnerStatus: PropTypes.shape({
      label: PropTypes.string,
      value: PropTypes.string,
    }),
    emotionalRelationshipStatuses: PropTypes.arrayOf(
      PropTypes.shape({
        label: PropTypes.string,
        value: PropTypes.string,
      })
    ),
    levelsOfSupport: PropTypes.arrayOf(PropTypes.string),
    levelsOfSupportOtherDescription: PropTypes.string,
    placementProviderEligibilitiesJson: PropTypes.shape({
      checked: PropTypes.bool,
    }),
    placementProviderEligibilitiesDetails: PropTypes.string,
    fictiveKinDescription: PropTypes.string,
    additionalRelationshipDetails: PropTypes.string,
    verificationSource: PropTypes.string,
  }),
  setFormAttribute: PropTypes.func.isRequired,
};

export default RelationshipToChildSection;
