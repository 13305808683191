import { InputEthnicities, InputRadioGroup } from "@heart/components";
import PropTypes from "prop-types";
import { Fragment } from "react";

import { translationWithRoot } from "@components/T";
import ProtectedTribes from "@components/intake_protected_tribes/ProtectedTribes";

import { PROTECTED_TRIBES, NON_RECOGNIZED_TRIBES } from "@root/constants";

const { t: protectedTribesT } = translationWithRoot(
  "views.common.protected_tribe",
  {
    escapeJavascriptRoot: true,
  }
);
const { t } = translationWithRoot("agency_human.form.ethnicities_and_icwa");

const EthnicityAndICWASection = ({
  ethnicitiesValid,
  setEthnicitiesValid,
  racesValid,
  setRacesValid,
  formState,
  setFormAttribute,
  setFormState,
  useRacesInput,
}) => (
  <Fragment>
    <InputEthnicities
      useRacesInput={useRacesInput}
      selectedEthnicities={formState.ethnicities}
      onEthnicitiesChange={(newEthnicities, { valid }) => {
        setFormAttribute("ethnicities")(newEthnicities);
        setEthnicitiesValid(valid);
      }}
      selectedRaces={formState.races}
      onRacesChange={(newRaces, { valid }) => {
        setFormAttribute("races")(newRaces);
        setRacesValid(valid);
      }}
      error={
        ethnicitiesValid && racesValid ? "" : t("please_specify_ethnicity")
      }
    />
    <InputRadioGroup
      label={t("has_person_been_asked")}
      value={formState.askedAboutProtectedTribe}
      values={[
        { label: protectedTribesT("answer_yes"), value: true },
        { label: protectedTribesT("answer_no"), value: false },
      ]}
      onChange={askedAboutProtectedTribe =>
        setFormState({
          ...formState,
          askedAboutProtectedTribe,
          tribes: askedAboutProtectedTribe
            ? formState.tribes
            : {
                isProtectedTribeMember: null,
                tribeNames: [],
                tribeIdentification: null,
              },
        })
      }
    />
    <If condition={formState.askedAboutProtectedTribe}>
      <ProtectedTribes
        isRequired={true}
        anyTribeQuestionLabel={protectedTribesT("t_is_member_of_any_tribe")}
        tribeOptions={PROTECTED_TRIBES}
        nonRecognizedTribeOptions={NON_RECOGNIZED_TRIBES}
        inputValues={formState}
        values={formState.tribes}
        onChange={setFormAttribute("tribes")}
      />
    </If>
  </Fragment>
);
EthnicityAndICWASection.propTypes = {
  ethnicitiesValid: PropTypes.bool.isRequired,
  setEthnicitiesValid: PropTypes.func.isRequired,
  racesValid: PropTypes.bool.isRequired,
  setRacesValid: PropTypes.func.isRequired,

  formState: PropTypes.object.isRequired,
  setFormAttribute: PropTypes.func.isRequired,
  /** We need to use setFormState for the case where we're setting
   * more than one field in formState as updating state is asynchronous
   * and using setFormAttribute twice in a row creates a race condition.
   */
  setFormState: PropTypes.func.isRequired,

  /** Part of ethnicites/races migration. Remove in ENG-21169 */
  useRacesInput: PropTypes.bool.isRequired,
};

export default EthnicityAndICWASection;
