import { gql, useMutation, useQuery } from "@apollo/client";
import { Actions, LoadingOverlay, SurfaceForm, toast } from "@heart/components";
import useBintiForm from "@heart/components/forms/useBintiForm";
import I18n from "i18n-js";
import _ from "lodash";
import { useState } from "react";

import BintiPropTypes from "@lib/BintiPropTypes";
import preventDefault from "@lib/preventDefault";

import { accountForTimezone } from "./CreatePlacementPeriod";
import PlacementPeriodForm from "./PlacementPeriodForm";

const placementPeriodFragment = gql`
  fragment PlacementPeriod on PlacementPeriod {
    id
    agencyPlacementId
    startDate
    endDate
    placementType
    careProvider
    placementTypeDescription
    privateAgencyFamilyName
    privateAgencyFamilyPhoneNumber
    privateAgencyFamilyLicenseNumber
    privateAgencyAddressStreet
    privateAgencyAddressCity
    privateAgencyAddressState
    privateAgencyAddressZipcode
    canFamilyViewChildProfile
    isPreAdoptionPlacement
    child {
      id
      firstName
      lastName
      currentPlacementId
    }
    daysOfNotice
    placementEndedReason
    placementEndedReasonExplanation
    placementEndedRequester
    shouldPayStandardProvider
  }
`;

const UpdatePlacementPeriod = ({ childId, placementPeriodId }) => {
  const [errors, setErrors] = useState([]);
  const { data, loading } = useQuery(
    gql`
      ${placementPeriodFragment}
      query UpdatePlacementPeriod($placementPeriodId: ID!) {
        placementPeriod(placementPeriodId: $placementPeriodId) {
          ...PlacementPeriod
        }
      }
    `,
    {
      variables: {
        placementPeriodId: placementPeriodId,
      },
    }
  );

  const [updatePlacementPeriod, { loading: mutationLoading }] = useMutation(
    gql`
      ${placementPeriodFragment}
      mutation UpdatePlacementPeriod($input: UpdatePlacementPeriodInput!) {
        updatePlacementPeriod(input: $input) {
          placementPeriod {
            ...PlacementPeriod
          }
          errors
          redirectTo
        }
      }
    `,
    {
      onCompleted: ({ updatePlacementPeriod: response }) => {
        if (response.errors.length === 0) {
          toast.onNextPageLoad.success(
            I18n.t(
              "views.admin.placements.placement_period.updated_successfully"
            )
          );
          window.location = response.redirectTo;
        }

        setErrors(response.errors);
      },
    }
  );

  const { formState, setFormAttribute } = useBintiForm(data?.placementPeriod);

  return (
    <LoadingOverlay active={loading || mutationLoading}>
      <SurfaceForm
        title={`Placement for ${formState?.child?.firstName} ${formState?.child?.lastName}`}
        actions={<Actions />}
        onSubmit={preventDefault(() =>
          updatePlacementPeriod({
            variables: {
              input: {
                ..._.omit(formState, "child", "agencyPlacementId"),
                startDate: accountForTimezone(formState.startDate),
                endDate: accountForTimezone(formState.endDate),
              },
            },
          })
        )}
      >
        <PlacementPeriodForm
          childId={childId}
          placementPeriodId={placementPeriodId}
          formState={formState}
          setFormAttribute={setFormAttribute}
          errors={errors}
        />
      </SurfaceForm>
    </LoadingOverlay>
  );
};

UpdatePlacementPeriod.propTypes = {
  childId: BintiPropTypes.ID,
  placementPeriodId: BintiPropTypes.ID,
};

export default UpdatePlacementPeriod;
