import {
  ContainsTrustedHTML,
  InputCheckboxGroup,
  InputDate,
  InputDropdown,
  InputPhone,
  InputRadioGroup,
} from "@heart/components";
import inputStyles from "@heart/components/inputs/Input.module.scss";
import PropTypes from "prop-types";

import labelStyles from "@components/reusable_ui/forms/Label.module.scss";

import useFeatureFlag from "@lib/useFeatureFlag";

import Attachment from "./Attachment";
import Block from "./Block";
import ConditionalBlock from "./ConditionalBlock";
import FormFileUploader from "./FormFileUploader";
import GroupBlock from "./GroupBlock";
import ListBlock from "./ListBlock";
import QuestionnaireTextInput, {
  questionnaireTextInputTypes,
} from "./QuestionnaireTextInput";

const Question = props => {
  const { type, values, renderErrors } = props;
  const { error, ...propsWithoutError } = props;
  const { flag: ffShrineUploads } = useFeatureFlag(
    "ff_shrine_uploaded_form_files_01_2025"
  );

  // TAM has configured a bunch of custom HTML labels and descriptions
  // and we trust those, so we mark those as 'safe' so the HTML will render
  // without being escaped:
  const questionProps = {
    ...(renderErrors ? props : propsWithoutError),
    title: (
      <ContainsTrustedHTML
        html={props.title}
        trustedSource="TAM questionnaire config"
        className={labelStyles.label}
      />
    ),
    description: (
      <ContainsTrustedHTML
        html={props.description}
        trustedSource="TAM questionnaire config"
        className={inputStyles.description}
      />
    ),
  };

  if (type === "block") {
    const { items } = props;
    return <Block questions={values || items} {...questionProps} />;
  }
  if (type === "conditional_block") {
    return <ConditionalBlock {...questionProps} />;
  }
  if (type === "list_block") return <ListBlock {...questionProps} />;
  if (type === "group_block") return <GroupBlock {...questionProps} />;
  if (type === "radio" && values.length <= 6) {
    const { value, previousValue } = props;
    return (
      <InputRadioGroup
        {...questionProps}
        value={value || previousValue}
        label={questionProps.title}
      />
    );
  }
  if (type === "checkbox")
    return (
      <InputCheckboxGroup
        {...questionProps}
        label={questionProps.title}
        forLegacyCheckboxGroupAA
      />
    );
  if (type === "dropdown" || (type === "radio" && values.length > 6))
    return <InputDropdown {...questionProps} label={questionProps.title} />;
  if (type === "date")
    return <InputDate label={questionProps.title} {...questionProps} />;
  if (type === "phone_number")
    return <InputPhone label={questionProps.title} {...questionProps} />;
  if (type === "attachment") {
    if (ffShrineUploads) {
      return <FormFileUploader {...questionProps} />;
    }

    return <Attachment {...questionProps} />;
  }
  if (questionnaireTextInputTypes.includes(type)) {
    return <QuestionnaireTextInput {...questionProps} />;
  }
  return null;
};

export const questionPropTypes = {
  type: PropTypes.string.isRequired,
  value: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.array,
    PropTypes.number,
  ]),
  previousValue: PropTypes.string,
  values: PropTypes.array, // the options when specific multiple suggestions
  items: PropTypes.array,
  name: PropTypes.string,
  id: PropTypes.string,
  required: PropTypes.bool,
  description: PropTypes.node,
  title: PropTypes.node,
  max_characters: PropTypes.number,
  label: PropTypes.string,
  shouldPrePopulateFromPrevious: PropTypes.bool,
  shouldComparePreviousAnswers: PropTypes.bool,
  disabled: PropTypes.bool,
  error: PropTypes.string,
  renderErrors: PropTypes.bool,
};

Question.propTypes = questionPropTypes;
const QuestionWrapped = props => (
  <div className="question form-group" data-testid="question-wrapper">
    <Question {...props} />
  </div>
);
export default QuestionWrapped;
