import { gql, useMutation, useQuery } from "@apollo/client";
import {
  Button,
  Flex,
  InputCheckbox,
  InputDropdown,
  InputText,
  Text,
} from "@heart/components";
import { Spinner } from "@heart/components/icon/Icon";
import { PropTypes } from "prop-types";
import React, { useState } from "react";

import { translationWithRoot } from "@components/T";
import AgencyDropdown from "@components/shared/AgencyDropdown";
import BannerContainer from "@components/shared/banner/BannerContainer";

import AgencyRelationships from "@graphql/queries/AgencyRelationships.graphql";

import BintiPropTypes from "@lib/BintiPropTypes";
import useFeatureFlag from "@lib/useFeatureFlag";

const { t } = translationWithRoot("permissions.agency_relationships");

const CreateAgencyRelationship = gql`
  mutation CreateAgencyRelationship(
    $label: String
    $sourceAgencyId: ID!
    $targetAgencyId: ID!
    $permissionRuleSetId: ID
    $metadata: JSON
  ) {
    createAgencyRelationship(
      input: {
        label: $label
        sourceAgencyId: $sourceAgencyId
        targetAgencyId: $targetAgencyId
        permissionRuleSetId: $permissionRuleSetId
        metadata: $metadata
      }
    ) {
      agencyRelationship {
        id
      }
    }
  }
`;

const PermissionRuleSets = gql`
  query PermissionRuleSets {
    permissionRuleSets {
      id
      label
    }
  }
`;

/**
 * CreateAgencyRelationshipForm component renders a form to create a new agency
 * relationship.
 */
const CreateAgencyRelationshipForm = () => {
  const {
    data: permissionRuleSetsData,
    loading: permissionRuleSetsLoading,
    error: permissionRuleSetsError,
  } = useQuery(PermissionRuleSets);

  const [createAgencyRelationship, { loading: adding, error: addingError }] =
    useMutation(CreateAgencyRelationship, {
      refetchQueries: [{ query: AgencyRelationships }],
    });

  const create = data => {
    const fd = new FormData(data.target);
    const variables = {
      label: fd.get("label"),
      sourceAgencyId: fd.get("sourceAgencyId"),
      targetAgencyId: fd.get("targetAgencyId"),
      permissionRuleSetId: fd.get("permissionRuleSetId"),
    };

    const isPreventionChecked =
      data.target?.preventionAgencyRelationship?.checked;
    if (isPreventionChecked) {
      variables.metadata = {
        prevention: {
          referring_agency_id: fd.get("sourceAgencyId"),
          provider_agency_id: fd.get("targetAgencyId"),
        },
      };
    }
    createAgencyRelationship({ variables });
  };

  const permissionRuleSets = permissionRuleSetsData?.permissionRuleSets || [];
  const error = addingError || permissionRuleSetsError;

  if (adding || permissionRuleSetsLoading) {
    return <Spinner />;
  }

  if (error) {
    return (
      <BannerContainer type="error" message={`Ooopsie! ${error.message}`} />
    );
  }

  return <Form permissionRuleSets={permissionRuleSets} onSubmit={create} />;
};

const Form = ({ permissionRuleSets, onSubmit }) => {
  const { flag: ffPrevention } = useFeatureFlag("ff_prevention_03_2024");
  const [isPrevention, setIsPrevention] = useState(false);

  return (
    <Flex column>
      <Text textStyle="emphasis-200">Create Agency Relationship</Text>
      <form onSubmit={onSubmit} className="contains-inputs">
        <If condition={ffPrevention}>
          <InputCheckbox
            label={t("prevention_relationship")}
            id="preventionAgencyRelationship"
            name="preventionAgencyRelationship"
            onChange={checked => {
              setIsPrevention(checked);
            }}
            required={false}
          />
        </If>
        <InputText
          id="label"
          label={t("relationship_label")}
          name="label"
          required
          rows={1}
          type="text"
        />
        <AgencyDropdown
          label={isPrevention ? t("referring_agency") : t("source_agency")}
          id="sourceAgencyId"
          name="sourceAgencyId"
          required
        />
        <InputDropdown
          name="permissionRuleSetId"
          id="permissionRuleSetId"
          label={t("permission_rule_set")}
          values={permissionRuleSets.map(({ id, label }) => ({
            label: label,
            value: id,
          }))}
          required
        />
        <AgencyDropdown
          label={isPrevention ? t("provider_agency") : t("target_agency")}
          id="targetAgencyId"
          name="targetAgencyId"
          required
        />
        {/* TODO ENG-12656 to shrink button */}
        <div>
          <Button type="submit">{t("create_agency_relationship")}</Button>
        </div>
      </form>
    </Flex>
  );
};

Form.propTypes = {
  permissionRuleSets: PropTypes.arrayOf(
    PropTypes.shape({ label: PropTypes.string, id: BintiPropTypes.ID })
  ),
  onSubmit: PropTypes.func,
};

export default CreateAgencyRelationshipForm;
